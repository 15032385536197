import { private_server, public_server } from "./axios"
import { catalogs } from "./catalogs"
import { PRODUCT_DISCOUNT_TYPES, CURRENCY, GENDERS } from "./enums"
import { onGetFormatNumber } from "./formatNumber"
import moment from 'moment'

export const onGetJSONArrayData = (origin, initvalue = []) => {
    let result = initvalue
    try {
        if(origin?.data?.length > 0){
            const temp = origin?.data
            const decodedValue = decodeURIComponent(temp)
            const array = JSON.parse(decodedValue)
            if(Array.isArray(array)){
                result = [...array]
            }
        }
    } catch (error) {
        console.log('Invalid data', origin, error)
    }
    return result
}

export const onEncodeJSONArrayData = (data) => {
    return JSON.stringify({data:encodeURIComponent(JSON.stringify(data))})
}

export const onDecodeJSONArrayData = (data) => {
    let result = null
    if(data !== null){
        try {
            result = JSON.parse(data)
            if(result.data){
                result = decodeURIComponent(result.data)
            }
            
        } catch (error) {
            console.log(error)
            try {
                result = decodeURIComponent(data)
                result = JSON.parse(result)
                console.log(result)
                result = JSON.stringify(result.data)
            } catch (error) {
                
            }
           
    
        }
    }
    
    console.log("DECODEDDATA: ",result)
    return result
}

export const onDeprecatedDecodeJSONArrayData = (data) => {
    let result = null
    try {
        result = JSON.parse(data)
        result = decodeURIComponent(result)
    } catch (error) {
        
    }
    return result
}

export const onGetHarrisBenedict = (appointment, age=0, gender_id) => {
    let result = {
        ger:0,
        get:0
    }

    const weight = onGetNumber(appointment?.weight)
    const height = onGetNumber(appointment?.height)

    if(gender_id === GENDERS.MALE){
        result.ger = 66.5+(13.75*weight)+(1.850*height)-(4.676*age)
    }else{
        result.ger = 655.1+(9.563*weight)+(1.850*height)-(4.676*age)
    }

    return result
}

export const onGetFAO = (appointment, age=0, gender_id) => {
    let result = {
        ger:0,
        get:0
    }

    const weight = onGetNumber(appointment?.weight)

    if(gender_id === GENDERS.MALE){
        //console.log('Hombre', age, weight)
        if(age < 4){
                result.ger = (60.9*weight)-54
        }else if(age >= 4 && age <11){
            result.ger = (22.7*weight)+495
        }else if(age >= 11 && age <19){
            result.ger = (17.5*weight)+651
        }else if(age >= 19 && age <31){
            //console.log('Cai aqui')
            result.ger = (15.3*weight)+679
        }else if((age >= 31 && age <60)){
            result.ger = (11.6*weight)+879
        }else if(age >= 60){
            result.ger = (13.5*weight)+487
        }
    }else{
        //console.log('Mujer', age, weight)
        if(age < 4){
            result.ger = (61*weight)-51
        }else if(age >= 4 && age <11){
            result.ger = (22.5*weight)+499
        }else if(age >= 11 && age <19){
            result.ger = (12.2*weight)+746
        }else if(age >= 19 && age <31){
            result.ger = (14.7*weight)+496
        }else if(age >= 31 && age <60){
            result.ger = (8.7*weight)+829
        }else if(age >= 60){
            result.ger = (10.5*weight)+596
        }   
    }
    //console.log(result)
    return result
}

export const onGetMifflinStJeor = (appointment, age=0, gender_id) => {
    let result = {
        ger:0,
        get:0
    }

    const weight = onGetNumber(appointment?.weight)
    const height = onGetNumber(appointment?.height)

    if(gender_id === GENDERS.MALE){
        result.ger = (9.99*weight)+(6.25*height)+(4.92*age)+5
    }else{
        result.ger = (9.99*weight)+(6.25*height)+(4.92*age)-161
    }

    return result
}


export const onGetNumber = (value) => {
    let _number = onGetFormatNumber(value, '0.00')
    _number = parseFloat(_number)
    if(isNaN(_number)) _number = 0
    return _number
}

export const onGetIMCResult = (value) => {
    let result = 'Desconocido'

    //if(value < 18.5){
    //    result = 'Bajo peso'
    //}else if(value >=18.5 && value < 25){
    //    result = 'Peso normal'
    //}else if(value >=25 && value < 30){
    //    result = 'Sobrepeso'
    //}else if(value >=30 && value < 35){
    //    result = 'Obesidad I'
    //}else if(value >=35 && value < 40){
    //    result = 'Obesidad II'
    //}else if(value >=40){
    //    result = 'Obesidad III'
    //}else{
//
    //}
    let diagnosis = catalogs.imc_diagnostics.find(el => parseFloat(value) >= el.min && parseFloat(value) < el.max)
    //console.log(value, diagnosis)
    return diagnosis?.label ?? result 
} 


export const onGetSkinfoldDiagnosis = (appointment, age=0, gender_id) => {
    let result = {
        skinfold_total:0,
        dw_density:0,
        dw_percetage:0,
        carter_percentage:0
    }

    let skinfold_tricep = appointment?.skinfold_tricep ?? 0.00
    let skinfold_subscapularis = appointment?.skinfold_subscapularis ?? 0.00
    let skinfold_biceps = appointment?.skinfold_biceps ?? 0.00
    let skinfold_supraspinal = appointment?.skinfold_supraspinal ?? 0.00
    let skinfold_iliac_crest = appointment?.skinfold_iliac_crest ?? 0.00
    let skinfold_core = appointment?.skinfold_core ?? 0.00
    let skinfold_front_thigh = appointment?.skinfold_front_thigh ?? 0.00
    let skinfold_medial_calf = appointment?.skinfold_medial_calf ?? 0.00


    const totalsum = skinfold_tricep + skinfold_subscapularis + skinfold_biceps + skinfold_supraspinal
    +  skinfold_iliac_crest + skinfold_core + skinfold_front_thigh + skinfold_medial_calf
    //result = totalsum
    const totaldurning = skinfold_tricep + skinfold_subscapularis + skinfold_biceps + skinfold_supraspinal
    const totalcarter = skinfold_tricep + skinfold_subscapularis + skinfold_supraspinal 
    + skinfold_core + skinfold_front_thigh + skinfold_medial_calf

    //////////////////////////  DURNING & WOMERSLY //////////////////////////
    
    let dw_density = 0
    if(gender_id === GENDERS.MALE){
        console.log('Hombre', age)
        if(age < 17){
            dw_density = 1.1533 - (0.0643 * Math.log10(totaldurning))
        }else if(age >= 17 && age <20){
            dw_density = 1.1620 - (0.0630 * Math.log10(totaldurning))
        }else if(age >= 20 && age <30){
            dw_density = 1.1631 - (0.0632 * Math.log10(totaldurning))
        }else if(age >= 30 && age <40){
            dw_density = 1.1422 - (0.0544 * Math.log10(totaldurning))
        }else if((age >= 40 && age <50)){
            dw_density = 1.1620 - (0.0700 * Math.log10(totaldurning))
        }else if(age >= 50){
            dw_density = 1.1715 - (0.0779 * Math.log10(totaldurning))
        }
    }else{
        //console.log('Mujer', age, weight)
        if(age < 17){
            dw_density = 1.1369 - (0.0598 * Math.log10(totaldurning))
        }else if(age >= 17 && age <20){
            dw_density = 1.1549 - (0.0678 * Math.log10(totaldurning))
        }else if(age >= 20 && age <30){
            dw_density = 1.1599 - (0.0717 * Math.log10(totaldurning))
        }else if(age >= 30 && age <40){
            dw_density = 1.1423 - (0.0632 * Math.log10(totaldurning))
        }else if((age >= 40 && age <50)){
            dw_density = 1.1333 - (0.0612 * Math.log10(totaldurning))
        }else if(age >= 50){
            dw_density = 1.1339 - (0.0645 * Math.log10(totaldurning))
        }
    }

    result.skinfold_total = totalsum
    result.dw_density = dw_density
    result.dw_percetage = (495/dw_density)-450
    
    //////////////////////////  CARTER //////////////////////////
    let carter = 0
    if(gender_id === GENDERS.MALE){
        carter = 0.1051*totalcarter + 2.58
    }else{
        carter = 0.1548*totalcarter + 3.58
    }

    result.carter_percentage = carter
    console.log(result)
    return result
}

export const onGetICC = (appointment) => {
    if(!appointment?.circumference_hips) return 0
    return appointment?.circumference_waist / appointment?.circumference_hips 
}

export const onGetICCDiagnosisID = (icc, gender_id) => {
    let result = undefined
    const diagnosis = catalogs.icc_diagnostics.find(el => {
        if(gender_id === GENDERS.MALE){
            return icc >= el?.min?.male && icc < el?.max?.male
        }else{
            return icc >= el?.min?.female && icc < el?.max?.female
        }
    })
    return diagnosis?.value ?? result
}

export const onGetQuestionaryDiagnosis = (questionary) => {
    let result = {
        "digestion_status_id":onGetDiagnosisElement(questionary?.digestion?.points,catalogs.digestion_statuses),
        "physical_condition_id":onGetDiagnosisElement(questionary?.physical_condition?.points,catalogs.physical_conditions),
        "sleep_quality_id":onGetDiagnosisElement(questionary?.sleep_condition?.points,catalogs.sleep_qualities, true),
        "energy_level_id":onGetDiagnosisElement(questionary?.energy_level?.points,catalogs.energy_levels)
    }

    return result
}

export const onGetDiagnosisElement = (points, catalog, log) => {
    if(log){
        console.log(points, catalog)
    }
    let result = 1
    const elFind = catalog.find(el => points >= el.min && points <= el.max)
    return elFind?.value ?? result
}

export const onGetRangeAge = (birth_date) => {
    const isvalid =  moment(birth_date).isValid()
    if(!isvalid) return ''
    const age = moment().diff(birth_date,'years')

    if(age >= 18 && age <30){
        return '18 - 29'
    }else if(age >= 30 && age <40){
        return '30 - 39'
    }else if(age >= 40 && age <50){
        return '40 - 49'
    }else if(age >= 50 && age <60){
        return '50 - 59'
    }else if(age >= 60 && age <70){
        return '60 - 69'
    }else if(age >= 70 && age <80){
        return '70 - 79'
    }else if(age >= 80 && age <90){
        return '80 - 89'
    }else if(age >= 90 && age <100){
        return '90 - 99'
    }
    
    
    return ''
}























export const onGetProductItemPrice = (product) => {
    let result = {
        current:product?.price??'',
        previous:null,
        free:0,
    }
    //console.log('PRICE ANALYSE', product)
    const tax_lines = product?.taxable === 1 ? (product?.product_taxes || product?.tax_lines) : []
    const product_quantity = parseInt(product?.quantity || product?.product_quantity)
    //console.log('product_quantity',product_quantity)
    const _product_discount_type_id = product?.product_discount_type_id || product?.sale_discount_type_id
    //console.log('product_discount_type',_product_discount_type_id)
    if(product?.sale === 1 && _product_discount_type_id === PRODUCT_DISCOUNT_TYPES.DISCOUNT){
        //console.log('DISCOUNT')
        result.current = parseFloat(product?.sale_price)
        result.previous = parseFloat(product?.price)
        result.free = 0

    }else if(product?.sale === 1 && _product_discount_type_id === PRODUCT_DISCOUNT_TYPES.BUNDLE){
        //console.log('BUNDLE')            
        if(product_quantity >= product.sale_bundle_quantity){
            result.current = product?.sale_bundle_price
            result.previous = product?.price
            result.free = 0
        }
    }else if(product?.sale === 1 && _product_discount_type_id === PRODUCT_DISCOUNT_TYPES.BUNDLE_QXQ){
        //console.log('BUNDLE_QXQ')        
        //const freeItems = product?.sale_bundle_included_free
        result.current = product?.price
        result.previous = null
        result.free = product?.sale_bundle_included_free || product?.sale_bundle_free_quantity
    }

    let current_tax_sum = 0
    let previous_tax_sum = 0
    if(product?.taxable === 1){
        tax_lines.forEach(item => {
            let _tax = parseFloat(item?.rate??0.00)
            if(_tax > 1) _tax = _tax/100
            current_tax_sum += result.current*(parseFloat(_tax))
            previous_tax_sum += result.previous*(parseFloat(_tax))
        })
    }

    result.current = parseFloat(result.current) + current_tax_sum
    result.previous = parseFloat(result.previous) + previous_tax_sum

    return result

}

export const isProductAlreadyInCart = (product, line_items, key) => {
    const _key = key ? key : 'product_id' 
    if(!line_items) return null
    if(!Boolean(line_items.length)) return null
    if(!product) return null
    const cart_product = line_items.find(el => el.product_id === product[_key])
    if(!cart_product) return null
    return cart_product

}

export const onGetCartId = async(cart, user) => {
    let cart_id = null
    let type = 'PUBLIC'
    if(user){
        //console.log('There is a user')
        type = 'PRIVATE'
        if(!cart){
            const create_cart_request = await private_server.post(`/cart/me`, {currency_id:CURRENCY.MXN})
            cart_id = create_cart_request.data.data.cart_id           
        }else{
            cart_id = cart.id_cart
        }
    }else{
        //console.log('There is no user')
        const _public_cart_id = localStorage.getItem('public_cart_id')
        if(_public_cart_id){
            //console.log('There is a public cart id')
            cart_id = _public_cart_id
        }else{
            //console.log('There is no public cart id')
            const create_cart_request = await public_server.post(`/cart/public`, {currency_id:CURRENCY.MXN})
            console.log(create_cart_request.data.data)
            cart_id = create_cart_request.data.data.cart_id     
            localStorage.setItem('public_cart_id', cart_id) 
        }
    }

    return {cart_id, type}
}   

///////////////////// SERVER HANDLERS ///////////////////////////
export const updateAuthorizationHeader = (server) => {
    const token = localStorage.getItem('accessToken');
    if(!token){
        console.log('Token not valid')
        return
    }
    server.defaults.headers.common['Authorization'] = token;
}


export const onAddRemoveProductToCart = async(cart, product, quantity, line_item, user,product_key) => {
    const key = product_key ? product_key : 'product_id'
    let updated_cart, cart_request
    updateAuthorizationHeader(private_server)
    let data2send = {product_id:product[key]}

    ////////////////////  GET CART ID /////////////////////////////////////////
    const {cart_id, type} = await onGetCartId(cart, user)
    const id_cart = cart_id

    ////////////////////  ADD REMOVE LINE ITEM AFTER GETTING CART ID /////////////////////////////////////////
    if(!line_item){
        console.log('Need to post a line item')
        if(type === 'PRIVATE'){
            data2send.quantity = quantity
            await private_server.post(`/lineitem/me/${id_cart}`, data2send)
            cart_request = await private_server.get(`/cart/me/${id_cart}`)
            updated_cart = cart_request.data.data.cart_data
            
        }else{
            data2send.quantity = quantity
            await public_server.post(`/lineitem/public/${id_cart}`, data2send)
            cart_request = await public_server.get(`/cart/public/${id_cart}`)
            updated_cart = cart_request.data.data.cart_data
        }
    }else{
        console.log('Need to patch a line item')
        const {id_line_item} = line_item
        delete data2send.product_id
        data2send.quantity = quantity
        console.log(data2send)
        if(user){
            if(quantity > 0) await private_server.patch(`/lineitem/me/${id_cart}/${id_line_item}`, data2send)
            if(quantity === 0) await private_server.delete(`/lineitem/me/${id_cart}/${id_line_item}`)
            cart_request = await private_server.get(`/cart/me/${id_cart}`)
            updated_cart = cart_request.data.data.cart_data
        }else{
            if(quantity > 0) await public_server.patch(`/lineitem/public/${id_cart}/${id_line_item}`, data2send)
            if(quantity === 0) await public_server.delete(`/lineitem/public/${id_cart}/${id_line_item}`)
            cart_request = await public_server.get(`/cart/public/${id_cart}`)
            updated_cart = cart_request.data.data.cart_data
        }
        
    }

    return updated_cart 
}
