import { m } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Button, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../../../components/animate';
// assets
import { PageNotFoundIllustration } from '../../../assets/illustrations';

const Page404 = () => {
    return ( 
        <>
            <Helmet>
                <title> 404 Pagina no encontrada | Velfare </title>
            </Helmet>

            <MotionContainer>
                <m.div variants={varBounce().in}>
                    <Typography variant="h3" paragraph>
                        Disculpa, esta página no fue encontrada!
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <Typography sx={{ color: 'text.secondary' }}>
                        Disculpanos, no pudimos encontrar la página que buscabas. Por favor verifica la URL que estás tratando de acceder, revisa con cuidado que este escrita correctamente
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <PageNotFoundIllustration
                        sx={{
                        height: 260,
                        my: { xs: 5, sm: 10 },
                        }}
                    />
                </m.div>

                <Button to="/auth/login" component={RouterLink} size="large" variant="contained">
                    Regresar
                </Button>
            </MotionContainer>
        </>
    );
}
 
export default Page404;