import { CLIENT_ID } from "../../config";

export const zonesdb = [
    {
        id:1,
        label:"Chillers",
        icon:"/assets/platform/chiller.jpg",
        client_id:CLIENT_ID,
        
        metrics:[
            {
                device_id:2,
                type:'flow',
                name:'Flujo chillers',
                icon:"mdi:water-flow",
            },
            {
                device_id:1,
                type:'energy',
                name:'Chiller 2',
                icon:"carbon:lightning",
            },
            {
                device_id:2,
                type:'energy',
                name:'Chiller 3',
                icon:"carbon:lightning",
            },
            {
                device_id:3,
                type:'energy',
                name:'Torre ventilador 1',
                icon:"carbon:lightning",
            },
            {
                device_id:4,
                type:'energy',
                name:'Torre ventilador 2',
                icon:"carbon:lightning",
            },
            {
                device_id:5,
                type:'energy',
                name:'Bomba glicol 1',
                icon:"carbon:lightning",
            },
            {
                device_id:6,
                type:'energy',
                name:'Bomba glicol 2',
                icon:"carbon:lightning",
            },
            {
                device_id:7,
                type:'energy',
                name:'Bomba torre 2',
                icon:"carbon:lightning",
            },
            
        ]
    },
    {
        id:2,
        label:"BOILERS",
        icon:"/assets/platform/boiller.jpg",
        client_id:CLIENT_ID,
        metrics:[
            {
                device_id:3,
                type:'flow',
                name:'Flujo KV11',
                icon:"mdi:water-flow",
            },
            {
                device_id:4,
                type:'flow',
                name:'Flujo KV21',
                icon:"mdi:water-flow",
            },
           
            {
                device_id:8,
                type:'energy',
                name:'KV11 ventilador 1',
                icon:"carbon:lightning",
            },
            {
                device_id:9,
                type:'energy',
                name:'KV21 ventilador 1',
                icon:"carbon:lightning",
            },
            {
                device_id:11,
                type:'energy',
                name:'KV11 ventilador 2',
                icon:"carbon:lightning",
            },
            {
                device_id:12,
                type:'energy',
                name:'KV21 ventilador 2',
                icon:"carbon:lightning",
            },
        ]
    },
    {
        id:3,
        label:"GERMINACION Y REMOJO",
        icon:"/assets/platform/mashing.jpg",
        client_id:CLIENT_ID,
        metrics:[
            {
                device_id:1,
                type:'flow',
                name:'Flujo germinación',
                icon:"mdi:water-flow",
            },
            {
                device_id:10,
                type:'energy',
                name:'Extractor',
                icon:"carbon:lightning",
            },
            {
                device_id:13,
                type:'energy',
                name:'Germinador',
                icon:"carbon:lightning",
            },
            {
                device_id:14,
                type:'energy',
                name:'Soplador',
                icon:"carbon:lightning",
            },
        ]
    },
]