import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Card, CardHeader, Box } from '@mui/material';
// components
import Chart, { useChart } from '../chart';

// ----------------------------------------------------------------------

MetricsChart.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function MetricsChart({ title, subheader, chart, ...other }) {
  const { colors, xdata, series, options } = chart;

  const [seriesData, setSeriesData] = useState('2019');

  const chartOptions = useChart({
    colors,
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      //show:true,
      show:false,
      categories:xdata,
      label:{
        show:false
      }
    },
    ...options,
  });

  return (
    <Card {...other}>
      <CardHeader
        title={title}
        subheader={subheader}
      />


        <Box  sx={{ mt: 3, mx: 3 }} dir="ltr">
          <Chart type="area" series={series} options={chartOptions} height={364} />
        </Box>
    </Card>
  );
}
