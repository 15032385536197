import { Box, Button, Card, Grid, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import useFlowDevice from '../../../../hooks/api/useFlowDevice';
import CustomSmallSelect from '../../../../components/custom-input/CustomSmallSelect';
import FileFilterButton from '../../../../components/actions/FileFilterButton';
import Iconify from '../../../../components/iconify';
import { useDateRangePicker } from '../../../../components/date-range-picker';
import { endOfDay, endOfMonth, startOfDay, startOfMonth, subDays, subMonths } from 'date-fns';
import useModal from '../../../../hooks/useModal';
import DateRangePicker from '../../../../components/date-range-picker/DateRangePicker';
import { fDate, onGetDateFormat, onGetDateWithTimezone } from '../../../../utils/formatTime';
import { MetricsChart } from '../../../../components/e-commerce';
import { fNumber } from '../../../../utils/formatNumber';
import useEnergyDevice from '../../../../hooks/api/useEnergyDevice';

import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
import { s2ab } from '../../../../utils/general';

const samples = [
    {value:1, label:'1'},
    {value:5, label:'5'},
    {value:15, label:'15'},
    {value:30, label:'30'},
    {value:60, label:'60'},
]


const EnergyDeviceChart = ({
    device_id,
    dateRangeManager,
    sample,
    update,
    onResetUpdate
}) => {
    const today = new Date()

    const [sending, setSending] = useState(false)
    const [selected, setSelected] = useState(0)
    const energyDeviceManager = useEnergyDevice()

    const [current_chart_data, setCurrentChartData] = useState(null)
    const [consumption_chart_data, setConsumptionChartData] = useState(null)
    const [frequency_chart_data, setFrequencyChartData] = useState(null)
    const [power_chart_data, setPowerChartData] = useState(null)
    const [timedata, setTimeData] = useState([])

    const onDownloadCSV = useCallback(() => {
        //console.log(temperature_data)
        //console.log(flow_data)
        //console.log(timedata)

        let data2excel = [
            ['Fecha', 'Corriente (A)', 'Consumo (kWh)', 'Potencia (kW)', 'Frecuencia (Hz)']
        ]

        for(let i=0; i<timedata.length; i++){
            data2excel.push([
                fDate(timedata[i], 'dd-MM-yyyy HH:mm'),
                current_chart_data.series[0].data[i],
                consumption_chart_data.series[0].data[i],
                power_chart_data.series[0].data[i],
                frequency_chart_data.series[0].data[i],
            ])
        }

        console.log(data2excel)
        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Siemens Grajales",
            Subject: "General Data",
            Author: "Trionix Technologies",
            CreatedDate: new Date()
        };
        wb.SheetNames.push("Registros");

        let ws_data_registers = data2excel
        let ws_registers = XLSX.utils.aoa_to_sheet(ws_data_registers)
        wb.Sheets["Registros"] = ws_registers;

        let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
        FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),'energy_data.xlsx');

    }, [current_chart_data, timedata, consumption_chart_data, frequency_chart_data, power_chart_data])



    const onRequestData = useCallback(async() => {
        setSending(true)

        const stdate =  fDate(dateRangeManager.startDate, 'yyyy-MM-dd')
        const eddate =  fDate(dateRangeManager.endDate, 'yyyy-MM-dd')

        const filterdata = {
            "AND":[
                {"field":"energy_device_id","operator":"=","value":device_id},
                {"field":"timestamp","operator":">=","value":stdate},
                {"field":"timestamp","operator":"<=","value":eddate},
            ]
        }

        console.log(filterdata)

        try {
            const result = await energyDeviceManager.onGetAll({
                order:0,
                order_by:JSON.stringify(["timestamp"]),
                average_samples:sample,
                filter:JSON.stringify(filterdata)
            })
            console.log(result)

            let _xdata = []
            let _flows = []
            let _temp_in = []
            let _temp_out = []

            let _currents = []
            let _powers = []
            let _consumtions = []
            let _frequency = []
            let _timedata = []


            for(let i=0; i<result.data.length; i++){
                const temp = result.data[i]
                console.log(temp)
                _timedata.push(temp.timestamp)
                _xdata.push(onGetDateFormat(temp.timestamp, 'DD MMM, HH:mm'))
                _currents.push(fNumber(temp.current, '0.00'))
                _powers.push(fNumber(temp.power, '0.00'))
                _consumtions.push(fNumber(temp.consumption, '0.00'))
                _frequency.push(fNumber(temp.frequency, '0.00'))
            }

            console.log(_xdata)
            console.log(_flows)
            console.log(_temp_in)
            console.log(_temp_out)

            let currentchart = {
                xdata:_xdata,
                series:[
                    {name:'Corriente (A)', data: _currents}
                ]
            }

            let powerchart = {
                xdata:_xdata,
                series:[
                    {name:'Potencia (kW)', data: _powers}
                ]
            }

            let consumptionchart = {
                xdata:_xdata,
                series:[
                    {name:'Consumo (kWh)', data: _consumtions}
                ]
            }

            let frequencychart = {
                xdata:_xdata,
                series:[
                    {name:'Frecuencia (Hz)', data: _frequency}
                ]
            }

            

            setTimeData(_timedata)
            setCurrentChartData(currentchart)
            setPowerChartData(powerchart)
            setConsumptionChartData(consumptionchart)
            setFrequencyChartData(frequencychart)
            onResetUpdate()

        } catch (error) {
            
        }
        setSending(false)
    }, [sample, device_id, dateRangeManager.startDate, dateRangeManager.endDate, dateRangeManager])

    useEffect(() => {
        console.log("Dome")
        if(device_id && update){
            onRequestData()
        }
    }, [device_id, sample, update])


    return ( 
        <Box>
            <Tooltip title="Descarga los datos visualizados en un archivo de excel">
                <Button variant='contained' onClick={onDownloadCSV}>
                    Descargar datos
                </Button>
            </Tooltip>
            <Box sx={{
                mt:3,
            }}>

                <Stack spacing={2}>
                    {current_chart_data && <Box>
                        <MetricsChart
                            title="Corriente (A)"
                            subheader=""
                            chart={current_chart_data}
                        />
                    </Box>}

                    {consumption_chart_data && <Box>
                        <MetricsChart
                            title="Consumo (kWh)"
                            subheader=""
                            chart={consumption_chart_data}
                        />
                    </Box>}

                    

                    {power_chart_data && <Box>
                        <MetricsChart
                            title="Potencia (kW)"
                            subheader=""
                            chart={power_chart_data}
                        />
                    </Box>}

                    {frequency_chart_data && <Box>
                        <MetricsChart
                            title="Frecuencia (Hz)"
                            subheader=""
                            chart={frequency_chart_data}
                        />
                    </Box>}
                </Stack>
                
            </Box>
        </Box>
     );
}
 
export default EnergyDeviceChart;