import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { sleep } from '../../../utils/general'
import { zonesdb } from '../../../_mock/clients/zonesdb'
import { devicesdb } from '../../../_mock/clients/devicesdb'
import useClient from '../../../hooks/api/useClient'

const useBusinessZoneDetails = () => {

    const {idzone, idclient} = useParams()
    const clientManager = useClient()


    const [devices, setDevices] = useState([])
    const [zone, setZone] = useState()
    const [loading, setLoading] = useState(false)
    const [client, setClient] = useState()
    const [selected_point, setSelectedPoint] = useState()

    const initialize = useCallback(async() => {
        setLoading(true)
        await sleep(300)
        const tempzone =  zonesdb.find(el => el.id == idzone)
        setZone(tempzone)
        //const tempdevices = devicesdb.filter(el => el.zone_id == idzone)
        console.log(tempzone)
        const _metrics = tempzone.metrics
        setDevices(_metrics)
        if(_metrics.length){
            setSelectedPoint(_metrics[0])
        }
        
        try {
            const request = await clientManager.actions.onGet(idclient)
            setClient(request)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }, [idzone, idclient])

    useEffect(() => {
        initialize()
    }, [idzone, idclient])

    return {
        loading, 
        devices,
        zone,
        client,
        selected_point,
        initialize,
        setSelectedPoint
    };
}
 
export default useBusinessZoneDetails;