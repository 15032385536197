import { Box, Button, Card, Grid, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import useFlowDevice from '../../../../hooks/api/useFlowDevice';
import useModal from '../../../../hooks/useModal';
import { fDate, onGetDateFormat } from '../../../../utils/formatTime';
import { MetricsChart } from '../../../../components/e-commerce';
import { fNumber } from '../../../../utils/formatNumber';
import { blue, orange, red } from '@mui/material/colors';

import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
import { s2ab } from '../../../../utils/general';

const samples = [
    {value:1, label:'1'},
    {value:5, label:'5'},
    {value:15, label:'15'},
    {value:30, label:'30'},
    {value:60, label:'60'},
]


const FlowDeviceChart = ({
    device_id,
    dateRangeManager,
    sample,
    update,
    onResetUpdate
}) => {
    const today = new Date()

    const [sending, setSending] = useState(false)
    //const [selected, setSelected] = useState(0)
    //const [sample, setSample] = useState(60)
    const [timedata, setTimeData] = useState([])
    const flowDeviceManager = useFlowDevice()
    const [flow_data, setFlowData] =useState(null)
    const [temperature_data, setTemperatureData] =useState(null)

    const modalManager = useModal({
        date_selector:false
    })

    const {modals} = modalManager

    //const onCloseDatePicker = useCallback(() => {
    //    modalManager.onChangeModalStatus('date_selector', false)
    //    console.log('Update filter')
    //    onRequestData()
    //}, [dateRangeManager.startDate, dateRangeManager.endDate, dateRangeManager])

    const onDownloadCSV = useCallback(() => {
        //console.log(temperature_data)
        //console.log(flow_data)
        //console.log(timedata)

        let data2excel = [
            ['Fecha', 'Flujo (m3/h)', 'Temp. In (°C)', 'Temp. Out (°C)']
        ]

        for(let i=0; i<timedata.length; i++){
            data2excel.push([
                fDate(timedata[i], 'dd-MM-yyyy HH:mm'),
                flow_data.series[0].data[i],
                temperature_data.series[0].data[i],
                temperature_data.series[1].data[i],
            ])
        }

        console.log(data2excel)
        let wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Siemens Grajales",
            Subject: "General Data",
            Author: "Trionix Technologies",
            CreatedDate: new Date()
        };
        wb.SheetNames.push("Registros");

        let ws_data_registers = data2excel
        let ws_registers = XLSX.utils.aoa_to_sheet(ws_data_registers)
        wb.Sheets["Registros"] = ws_registers;

        let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
        FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),'flow_data.xlsx');

    }, [temperature_data, flow_data, timedata])



    const onRequestData = useCallback(async() => {
        setSending(true)

        const stdate =  fDate(dateRangeManager.startDate, 'yyyy-MM-dd')
        const eddate =  fDate(dateRangeManager.endDate, 'yyyy-MM-dd')

        const filterdata = {
            "AND":[
                {"field":"flow_device_id","operator":"=","value":device_id},
                {"field":"timestamp","operator":">=","value":stdate},
                {"field":"timestamp","operator":"<=","value":eddate},
            ]
        }

        try {
            const result = await flowDeviceManager.onGetAll({
                order:0,
                order_by:JSON.stringify(["timestamp"]),
                average_samples:sample,
                filter:JSON.stringify(filterdata)
            })
            console.log(result)

            let _xdata = []
            let _flows = []
            let _temp_in = []
            let _temp_out = []
            let _timedata = []

            for(let i=0; i<result.data.length; i++){
                const temp = result.data[i]
                _timedata.push(temp.timestamp)
                _xdata.push(onGetDateFormat(temp.timestamp, 'DD MMM, HH:mm'))
                _flows.push(fNumber(temp.flow, '0.00'))
                _temp_in.push(fNumber(temp.t_in, '0.00'))
                _temp_out.push(fNumber(temp.t_out, '0.00'))
            }

            console.log(_xdata)
            console.log(_flows)
            console.log(_temp_in)
            console.log(_temp_out)

            let flowchart = {
                xdata:_xdata,
                series:[
                    {name:'Flujo (m3/h)', data: _flows}
                ]
            }

            let temperaturechart = {
                xdata:_xdata,
                series:[
                    {name:'Temperatura (in) (°C)', data: _temp_in},
                    {name:'Temperatura (out) (°C)', data: _temp_out}
                ],
                colors:[
                    blue[600],
                    red[600]
                ],

            }

            setFlowData(flowchart)
            setTemperatureData(temperaturechart)
            setTimeData(_timedata)
            

        } catch (error) {
            
        }
        onResetUpdate()
        setSending(false)
    }, [sample, device_id, dateRangeManager.startDate, dateRangeManager.endDate, dateRangeManager])

    useEffect(() => {
        console.log("Dome")
        if(device_id && update){
            onRequestData()
        }
    }, [device_id, sample, update])


    return ( 
        <Box>
            <Tooltip title="Descarga los datos visualizados en un archivo de excel">
                <Button variant='contained' onClick={onDownloadCSV}>
                    Descargar datos
                </Button>
            </Tooltip>
            
            <Box sx={{
                mt:3,
            }}>
                <Stack spacing={2}>
                    {flow_data && <Box>
                        <MetricsChart
                            title={<>Flujos (m<sup>3</sup>/h)</>}
                            subheader=""
                            chart={flow_data}
                        />
                    </Box>}

                    {temperature_data && <Box>
                        <MetricsChart
                            title="Temperaturas (°C)"
                            subheader=""
                            chart={temperature_data}
                        />
                    </Box>}
                </Stack>
                
            </Box>
        </Box>
     );
}
 
export default FlowDeviceChart;