import React from 'react';
import {Stack, Card} from '@mui/material'
import Image from '../images/Image';


const Logo = ({
    size,
}) => {
    return ( 
        <Card sx={{borderRadius:'50%'}}>
            <Image src="/favicon/apple-touch-icon.png" sx={{
                width:size
            }} objectFit='cover'/>
        </Card>
     );
}
 
export default Logo;