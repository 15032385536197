import { CLIENT_ID } from "../../config";

export const devicesdb = [
    /////////////////////************************** CHILLERS **************************/////////////////////////
    {
        id:1,
        client_id:CLIENT_ID,
        name:"Circuito agua glicolada",
        zone_id:1,
        icon:"mdi:water-flow",
        metric_id:""
    },
    {
        id:2,
        client_id:CLIENT_ID,
        name:"Chiller 1-A",
        zone_id:1,
        icon:"carbon:lightning",
        metric_id:""
    },
    {
        id:3,
        client_id:CLIENT_ID,
        name:"Chiller 1-B",
        zone_id:1,
        icon:"carbon:lightning",
        metric_id:""
    },
    {
        id:4,
        client_id:CLIENT_ID,
        name:"Chiller 2-A",
        zone_id:1,
        icon:"carbon:lightning",
        metric_id:""
    },
    {
        id:5,
        client_id:CLIENT_ID,
        name:"Chiller 2-B",
        zone_id:1,
        icon:"carbon:lightning",
        metric_id:""
    },
    {
        id:6,
        client_id:CLIENT_ID,
        name:"Torre enfriamiento 1 - Ventilador 1",
        zone_id:1,
        icon:"carbon:lightning",
        metric_id:""
    },
    {
        id:7,
        client_id:CLIENT_ID,
        name:"Torre enfriamiento 1 - Ventilador 2",
        zone_id:1,
        icon:"carbon:lightning",
        metric_id:""
    },
    {
        id:8,
        client_id:CLIENT_ID,
        name:"Bomba agua glicolada 1",
        zone_id:1,
        icon:"carbon:lightning",
        metric_id:""
    },
    {
        id:9,
        client_id:CLIENT_ID,
        name:"Bomba agua glicolada 2",
        zone_id:1,
        icon:"carbon:lightning",
        metric_id:""
    },
    /////////////////////************************** BOILERS **************************/////////////////////////
    {
        id:100,
        client_id:CLIENT_ID,
        name:"Circuito agua caliente 1",
        zone_id:2,
        icon:"mdi:water-flow",
        metric_id:"mdi:water-flow"
    },
    {
        id:101,
        client_id:CLIENT_ID,
        name:"Circuito agua caliente 2",
        zone_id:3,
        icon:"",
        metric_id:""
    },
    /////////////////////************************** KILLING **************************/////////////////////////
    {
        id:200,
        client_id:CLIENT_ID,
        name:"KV-11 Ventilador 1",
        zone_id:3,
        icon:"carbon:lightning",
        metric_id:""
    },
    {
        id:201,
        client_id:CLIENT_ID,
        name:"KV-11 Ventilador 2",
        zone_id:3,
        icon:"carbon:lightning",
        metric_id:""
    },
    {
        id:203,
        client_id:CLIENT_ID,
        name:"KV-21 Ventilador 1",
        zone_id:3,
        icon:"carbon:lightning",
        metric_id:""
    },
    {
        id:204,
        client_id:CLIENT_ID,
        name:"KV-21 Ventilador 2",
        zone_id:3,
        icon:"carbon:lightning",
        metric_id:""
    },
    /////////////////////************************** GERMINACION Y REMOJO **************************/////////////////////////
    {
        id:300,
        client_id:CLIENT_ID,
        name:"CO2",
        zone_id:3,
        icon:"",
        metric_id:""
    },
    {
        id:301,
        client_id:CLIENT_ID,
        name:"Circuito agua glicolada",
        zone_id:3,
        icon:"",
        metric_id:""
    },
    {
        id:303,
        client_id:CLIENT_ID,
        name:"Ventilador 1",
        zone_id:3,
        icon:"",
        metric_id:""
    },
    {
        id:304,
        client_id:CLIENT_ID,
        name:"Ventilador 2",
        zone_id:3,
        icon:"",
        metric_id:""
    },
    {
        id:304,
        client_id:CLIENT_ID,
        name:"Soplador 1",
        zone_id:3,
        icon:"",
        metric_id:""
    },
    {
        id:304,
        client_id:CLIENT_ID,
        name:"Extractor 1",
        zone_id:3,
        icon:"",
        metric_id:""
    },
]