import {  private_server } from '../../utils/axios';



const useClientProgram = () => {   

    const actions = {
        // Get list of all users
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/clientprogram/all', {params:params})
            result.data = request.data.data?.client_programs??[]
            result.count = request.data.data.count
            return result
        },
        onGet: async(idclientprogram) => {
            let result = null
            const request = await private_server.get(`/clientprogram/${idclientprogram}`)
            result = request.data.data.client_program
            return result
        },
        onAdd: async(data2send) => {
            let result = null
            const request = await private_server.post('/clientprogram', data2send)
            result = request.data.data?.client_program?.id_client_program
            return result
        },
        onEdit: async(idclientprogram, data2send) => {
            await private_server.patch(`/clientprogram/${idclientprogram}`, data2send)
        }, 
        onDelete: async(id) => {
            await private_server.delete(`/clientprogram/${id}`)
        },

    }

    return {
        actions
    }
}
 
export default useClientProgram;