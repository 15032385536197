import React, { useRef } from 'react';
import { 
    Card,
    Table,
    Button,
    TableBody,
    TableContainer,
    Grid,
    Box,
    Hidden,
    LinearProgress,
} from '@mui/material';

import PageLayout from '../../../layouts/PageLayout';
import useCartListPage from './useCartListPage';
import TableHeadCustom from '../../../components/table/TableHeadCustom';
import CartTableRow from './components/CartTableRow';
import TableEmptyRows from '../../../components/table/TableEmptyRows';
import TableNoData from '../../../components/table/TableNoData';
import { emptyRows } from '../../../utils/tables';
import Iconify from '../../../components/iconify';
import AddCartModal from '../../../modals/Carts/AddCartModal/AddCartModal';
import { makeStyles } from '@mui/styles';
import CartDetails from './components/CartDetails';
import AddProduct2CartModal from '../../../modals/Carts/AddProduct2CartModal/AddProduct2CartModal';
import TablePaginationCustom from '../../../components/table/TablePaginationCustom';
import EditCartModal from '../../../modals/Carts/EditCartModal/EditCartModal';
import BaseModal from '../../../components/modals/BaseModal';
import TableActionBar from '../../../components/table/TableActionBar';
import AssignUser2CartModal from '../../../modals/Carts/AssignUser2CartModal/AssignUser2CartModal';

const TABLE_HEAD = [
    { id: 'id_cart', label: 'ID', align: 'left' },
    { id: 'alias', label: 'Etiqueta', align: 'left' },
    { id: 'seller', label: 'Creado por', align: 'left' },
    { id: 'creation_date', label: 'Fecha', align: 'left' },
    //{ id: 'payment_subtotal', label: 'Total', align: 'left' },
    { id: '', label: '', align: '' },
  ];
  

const CartListPage = () => {

    const classes = useStyles()
    const {actions, data, system, modals} = useCartListPage()
    const {table_config, count, templates, view_cart, cart} = data
    
    const stackRef = useRef()

    const page = table_config.offset
    const rowsPerPage = table_config.limit

    const isNotFound = !templates.length

 
    return ( 
        <PageLayout
            title='Mis carritos'
            title_seo='Carritos | Velfare'
            loading={system.loading}
            action={<Button 
                variant='contained' 
                onClick={() => actions.onChangeModalStatus('add_cart', true)} 
                startIcon={<Iconify icon='eva:plus-fill'/>}
            >
                Nuevo carrito
            </Button>}
        >
            <div >
                <Hidden lgUp>
                    <CartModal 
                        open={view_cart} 
                        cart={cart} 
                        onClose={actions.onCloseCartDetails} 
                        loading={system?.cart_loading}
                        onAddProduct={() => actions.onChangeModalStatus('add_product_cart', true)}
                        onRefresh={actions.onRefreshData}
                    />  
                </Hidden>
                <AssignUser2CartModal 
                    cart={cart} 
                    open={modals.assign_user_cart} 
                    onClose={() => actions.onChangeModalStatus('assign_user_cart', false)} 
                    onRefresh={actions.onRefreshData}
                />
                <AddCartModal 
                    open={modals.add_cart} 
                    onClose={() => actions.onChangeModalStatus('add_cart', false)} 
                    onCompleted={actions.onSelectRow}
                    onRefresh={actions.onRefreshData}
                />
                <EditCartModal 
                    open={modals.edit_cart} origin={cart}
                    onClose={() => actions.onChangeModalStatus('edit_cart', false)} 
                    onRefresh={actions.onRefreshData}
                />
                <AddProduct2CartModal 
                    open={modals.add_product_cart} 
                    cart={cart} 
                    onRefresh={actions.onRefreshData}
                    onClose={() => actions.onChangeModalStatus('add_product_cart', false)} 
                />
                
                
                <Grid container direction='row' spacing={2}  >
                    <Grid item xs={12} 
                        md={12} 
                        lg={view_cart ? 7 : 12} 
                        xl={view_cart ? 8 : 12}
                    >
                        <Box sx={{marginBottom:2}}>
                            <TableActionBar onRequestSearch={actions.onRequestSearch}/>
                        </Box>
                        {(system.sending) && <Box sx={{marginBottom:2}}><LinearProgress /></Box> }
                        <Card className={classes.card} >
                            <TableContainer sx={{position: 'relative', overflow: 'unset'  }}>
                                    <Table  stickyHeader size={'small'} sx={{ minWidth: 800}}>
                                        <TableHeadCustom
                                            order={table_config.order} 
                                            orderBy={table_config.order_by}
                                            headLabel={TABLE_HEAD}
                                            rowCount={count}
                                            numSelected={0}
                                            onSort={actions.onSort}
                                        />

                                        <TableBody>
                                        {templates.map((row) => (
                                            <CartTableRow
                                                key={`cart-item-${row?.id_cart}`}
                                                row={row}
                                                selected={cart?.id_cart === row?.id_cart}
                                            //selected={selected.includes(row.id)}
                                                onSelectRow={() => actions.onSelectRow(row)}
                                                onDeleteRow={() => actions.onDeleteRow(row)}
                                                onEditRow={actions.onEditRow}
                                            />
                                        ))}

                                        <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, count)} />

                                        <TableNoData isNotFound={isNotFound} />
                                        </TableBody>
                                    </Table>
                            </TableContainer>
                        </Card>
                        <Box sx={{marginTop:2}}>
                            <TablePaginationCustom
                                tableFilter={table_config}               
                                total={count}
                                onChangePagination={actions.onChangePagination}
                            />
                        </Box>
                    </Grid>
                    <Hidden lgDown>
                        {view_cart && 
                        <Grid item lg={5} xl={4} >
                            
                            <CartDetails 
                                cart={cart}
                                onClose={actions.onCloseCartDetails} 
                                loading={system?.cart_loading}
                                onRefresh={actions.onRefreshData}
                                onAddProduct={() => actions.onChangeModalStatus('add_product_cart', true)}
                                onAssignCart2Patient={() => actions.onChangeModalStatus('assign_user_cart', true)}
                                /> 
                        
                        </Grid> }
                    </Hidden>
                    
                    
                    
                </Grid>
            </div>
        </PageLayout>
     );
}

const useStyles = makeStyles(theme => ({
    card_list:{
        transition:'1s all linear',
        
    },
    card:{
        overflow:'auto'
    }
}))
 
export default CartListPage;


const CartModal = ({open, onClose, cart, loading, onAddProduct, onRefresh}) => {
    return(
        <BaseModal open={open} title={`Carrito ${cart?.id_cart}`} onClose={onClose} >
            <CartDetails 
                cart={cart} ismodal={true}
                onClose={onClose} 
                loading={loading}
                onAddProduct={onAddProduct}
                onRefresh={onRefresh}
            /> 
        </BaseModal>
    )
    
}