import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { onGetUserData } from '../../../models/user-model';

// ----------------------------------------------------------------------

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop:8
  //padding: theme.spacing(2, 2.5),
  //borderRadius: Number(theme.shape.borderRadius) * 1.5,
  //backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

const StyledRoot = styled('div')(({ theme }) => ({
  //display: 'flex',
  //alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
  background:alpha("#FFF",0.5),
  color: theme.palette.primary.main
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user, pharmacy } = useAuthContext();

  const _user = onGetUserData(user, 'SIDEBAR_AVATAR')

  return (
    <Link to={PATH_DASHBOARD.user.account} component={RouterLink} underline="none" color="inherit">
      <StyledRoot>
        <StyledContainer>
          <CustomAvatar src={_user?.image} alt={_user?.displayName} name={_user?.displayName} />

          <Box sx={{ ml: 2, minWidth: 0 }}>
            <Typography variant="subtitle2" noWrap>
              {_user?.displayName}
            </Typography>

            <Typography variant="body2" noWrap sx={{ color: 'text.primary' }}>
              {_user?.role}
            </Typography>
          </Box>
        </StyledContainer>
        
      </StyledRoot>
    </Link>
  );
}
