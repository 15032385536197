import { private_server } from "../../utils/axios"

const useFlowDevice = () => {   

    const actions = {
        // Get list of all users
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            let request
            request = await private_server.get('/flow/all', {params:params})
            result.data = request.data.data?.flows??[]
            result.count = request.data.data.count
            return result
        }
    }

    return actions
}

export default useFlowDevice