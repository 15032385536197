import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router'
import PageLayout from '../../../layouts/PageLayout';
import { PATH_DASHBOARD } from '../../../routes/paths';
import {Grid, Card, Box, Stack, Typography} from '@mui/material'
import { useNavigate } from 'react-router';
import useBusinessZoneDetails from './useBusinessZoneDetails';
import Image from '../../../components/images/Image';
import Iconify from '../../../components/iconify';
import Scrollbars from 'react-custom-scrollbars';
import FlowDeviceChart from './charts/FlowDeviceChart';
import EnergyDeviceChart from './charts/EnergyDeviceChart';
import DateRangePicker, { useDateRangePicker } from '../../../components/date-range-picker';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import useModal from '../../../hooks/useModal';
import CustomSmallSelect from '../../../components/custom-input/CustomSmallSelect';
import FileFilterButton from '../../../components/actions/FileFilterButton';

const samples = [
    {value:1, label:'1'},
    {value:5, label:'5'},
    {value:15, label:'15'},
    {value:30, label:'30'},
    {value:60, label:'60'},
]

const BusinessZoneDetails = () => {

    const {idzone, idclient} = useParams()
    const today = new Date()

    const {
        loading,
        zone,
        devices,
        client,
        selected_point,
        setSelectedPoint
    } = useBusinessZoneDetails()

    const modalManager = useModal({
        date_selector:false
    })

    const [sample, setSample] = useState(60)
    const dateRangeManager = useDateRangePicker(subDays(startOfDay(today),1),endOfDay(today))
    const [update, setUpdate] = useState(true)

    const onCloseDatePicker = useCallback(() => {
        modalManager.onChangeModalStatus('date_selector', false)
        console.log('Update filter')
        //onRequestData()
        setUpdate(true)
    }, [dateRangeManager.startDate, dateRangeManager.endDate, dateRangeManager])

    const onChangeStartDate = (value) => {
        dateRangeManager.onChangeStartDate(value)
    }

    const onChangeEndDate = (value) => {
        dateRangeManager.onChangeEndDate(value)
    }

    const onUpdateSample = useCallback((e) => {
        setUpdate(true)
        setSample(e.target.value)
    }, [])

    let chartView = null

    if(selected_point){
        if(selected_point.type === 'flow'){
            chartView = <FlowDeviceChart 
            device_id={selected_point.device_id}
            dateRangeManager={dateRangeManager}
            sample={sample}
            update={update}
            onResetUpdate={() => setUpdate(false)}
            />
        }
        if(selected_point.type === 'energy'){
            chartView = <EnergyDeviceChart 
            device_id={selected_point.device_id}
            dateRangeManager={dateRangeManager}
            sample={sample}
            update={update}
            onResetUpdate={() => setUpdate(false)}
            
            />
        }
    }

    return ( 
        <PageLayout 
            title='Detalles de Zona'
            title_seo='Detalle de Zona | Siemens'
            enable_return
            loading={loading}
            
            links={[
                {name:'Dashboard', href:PATH_DASHBOARD.general.app},
                {name:'Plantas', href:PATH_DASHBOARD.general.clients},
                {name:'Planta', href:PATH_DASHBOARD.businesses.account(idclient)},
                {name:`Zona ${idzone}`},
            ]}
        >
            <div>
                <Stack direction='row' alignItems='center' spacing={2}>
                    <Card sx={{
                        display:'inline-flex'
                    }}>
                        <Box sx={{width:60}}>
                            <Image src={zone?.icon} ratio='1/1' />
                        </Box>
                    </Card>
                    <Stack>
                        <Typography variant='h4'>{zone?.label} </Typography>
                        <Typography variant='body2' color='text.secondary'>
                            {client?.name} - {client?.rfc}
                        </Typography>
                    </Stack>
                    
                </Stack>
                <Box sx={{
                    mt:5
                }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Scrollbars autoHeight autoHeightMax={550}>
                                <Box sx={{pr:2}}>
                                    <Stack spacing={2}>
                                        {devices.map(item =>{
                                            return(
                                                <ExtractionPointCard 
                                                    name={item.name}
                                                    client={client?.name}
                                                    subname={client?.rfc}
                                                    icon={item.icon}
                                                    selected={item.device_id === selected_point?.device_id && item.type === selected_point?.type}
                                                    onClick={() => {
                                                        setUpdate(true)
                                                        setSelectedPoint(item)
                                                    }}
                                                />
                                            )
                                        })}
                                    </Stack>
                                </Box>
                            </Scrollbars>
                            
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container justifyContent='flex-end' spacing={2}>
                                <Grid item>
                                    <CustomSmallSelect label='No. muestras' value={sample} onChange={onUpdateSample} sx={{width:120, py:1}}>
                                        {samples.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </CustomSmallSelect>
                                </Grid>
                                <Grid item>
                                    <FileFilterButton
                                        //isSelected={!!isSelectedValuePicker}
                                        startIcon={<Iconify icon="eva:calendar-fill" />}
                                        onClick={() => modalManager.onChangeModalStatus('date_selector', true)}
                                        >
                                        {dateRangeManager.shortLabel}
                                    </FileFilterButton>
                                </Grid>
                            </Grid>
                            
                            {chartView}
                        </Grid>
                        
                    </Grid>
                </Box>
                <DateRangePicker
                    variant="calendar"
                    startDate={dateRangeManager.startDate}
                    endDate={dateRangeManager.endDate}
                    open={modalManager.modals.date_selector}
                    onChangeStartDate={onChangeStartDate}
                    onChangeEndDate={onChangeEndDate}
                    onClose={onCloseDatePicker} 
                />
            </div>
        </PageLayout>

     );
}
 
export default BusinessZoneDetails;

const ExtractionPointCard = ({
    name,
    client,
    subname,
    icon, 
    src,
    selected,
    onClick
}) => {
    return(
        <Card sx={{
            background:"#F0F2F7",
            cursor:'pointer'
        }} onClick={onClick}>
            <Grid container>
                <Grid item xs>
                    <Box sx={{height:110, p:2}}>
                        <Stack sx={{height:1}} justifyContent='center'>
                            <Typography variant='subtitle1' color={selected ? 'secondary' : 'inherit'}>
                                {name}
                            </Typography>
                            <Typography variant='body2' color='text.secondary'>
                                {client} {subname??''}
                            </Typography>
                        </Stack>
                    </Box>
                    
                </Grid>
                <Grid item>
                    <Box  sx={{
                        height:110,  width:80, borderRadius:1,
                        background: theme => selected ? theme.palette.secondary.main : '#E4E8F0',
                        transition:'0.4s all linear'
                    }}>
                        <Stack sx={{height:1}} justifyContent='center' alignItems='center'>
                            <Iconify 
                                width={32}
                                icon={icon} 
                                color={selected ? 'white' : 'grey'}
                                sx={{
                                    transition:'0.4s all linear'
                                }}
                            />
                        </Stack>
                    </Box>
                    
                </Grid>
            </Grid>
        </Card>
    )
}